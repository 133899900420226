<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>小程序管理</BreadcrumbItem>
                <BreadcrumbItem>资讯管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem class="ivu-form-item-xl">
                    <Input v-model="filter.keywords" placeholder="咨讯名称" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="handleAdd" v-if="permissionButton.add">新增</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <Dropdown transfer>
                            <a href="javascript:void(0)">操作<Icon type="ios-arrow-down"></Icon></a>
                            <DropdownMenu class="table-dropdown" slot="list">
                                <DropdownItem v-if="permissionButton.view" @click.native="handleView(row, index)">详情</DropdownItem>
                                <DropdownItem v-if="permissionButton.edit" @click.native="handleEdit(row, index)">编辑</DropdownItem>
                                <DropdownItem class="table-delete" v-if="permissionButton.del" @click.native="handleDel(row, index)">删除</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <add-info v-model="showAddModal" v-on:getInfo="getInfo"></add-info>
        <edit-info v-model="showEditModal" :id="id" v-on:getInfo="getInfo"></edit-info>
        <view-info v-model="showViewModal" :id="id" v-on:getInfo="getInfo"></view-info>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {reqDelInfo, reqGetInfo} from "../../../api/admin-api";
import addInfo from "./add.vue"
import editInfo from "./edit.vue"
import viewInfo from "./view.vue"

export default {
    name: 'infoList',
    components: {
        addInfo,
        editInfo,
        viewInfo
    },
    data() {
        return {
            id: '',
            filter: {
                keywords: ''
            },
            loading: false,
            showAddModal: false,
            showEditModal: false,
            showViewModal: false,
            dataList: [],
            permissionButton: {
                add: false,
                edit: false,
                view: false,
                del: false
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '标题',
                    key: 'title',
                    tooltip: 'true',
                    minWidth: 300,
                },
                {
                    title: '分类',
                    key: 'classificationName',
                    width: 120,
                },
                {
                    title: '查看次数',
                    key: 'viewCounts',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '收藏次数',
                    key: 'collectCounts',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '更新人',
                    key: 'updatedByName',
                    width: 120,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        getInfo() {
            this.loading = true;
            this.dataList = [];
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
            };
            reqGetInfo(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        resetting() {
            this.filter.keywords = ''
            this.getInfo()
        },
        handleAdd() {
            this.showAddModal = true;
        },
        handleEdit(row, index) {
            this.id = row.id
            this.showEditModal = true;
        },
        handleView(row, index) {
            this.id = row.id
            this.showViewModal = true;
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除资讯',
                content: '确定删除资讯：'+row.title+'？此操作不可逆。',
                width: '320',
                onOk: () => {
                    reqDelInfo({id: row.id}).then((res)=>{
                        if(res.data.code === 1) {
                            this.getInfo();
                            this.$Message.success('删除成功');
                        }else {
                            this.$Message.error('删除失败');
                        }
                    }).catch(()=>{
                        this.$Message.error('删除失败');
                    })
                },
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getInfo();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getInfo();
        },
        doSearching() {
            this.pageNum = 1;
            this.getInfo();
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId
        permission.getPermissionButton(permissionId, this.permissionButton)
        this.getInfo()
    }
}
</script>
