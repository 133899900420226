<template>
    <Modal v-model="showForm" title="新增资讯" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="80">
            <FormItem label="资讯分类" prop="classification">
                <Select v-model="addForm.classification" filterable clearable>
                    <Option v-for="item in classificationArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="资讯标题" prop="title">
                <Input type="text" :maxlength="200" v-model="addForm.title" placeholder="请输入题目名称"/>
            </FormItem>
            <FormItem label="资讯内容" prop="content">
                <quill-editor v-model="addForm.content" :options="editorOption"></quill-editor>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {quillEditor} from 'vue-quill-editor';
// 富文本编辑器外部引用样式  三种样式三选一引入即可
import 'quill/dist/quill.snow.css';
import {checkEditorContent} from "../../../common/publicFuns";
import {reqGetDictionaryListByCode} from "../../../api/system-api";
import {reqAddInfo} from "../../../api/admin-api";

export default {
    name: "addInfo",
    components: {
        quillEditor
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            editorOption: { //  富文本编辑器配置
                modules:{
                    clipboard: {
                        // 粘贴版，处理粘贴时候带图片
                        matchers: [['img', this.handleCustomMatcher]],
                    },
                    toolbar:[
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        ['clean']
                    ]
                },
                theme: 'snow',
                placeholder: '请输入'
            },
            loading: false,
            showForm: this.value,
            classificationArray: [],
            addForm: {
                classification: '',
                title: '',
                content: '',
            },
            addFormRule: {
                title: [{required: true, message: '请输入资讯标题',  trigger: 'blur'}],
                classification: [{required: true, message: '请选择资讯分类', type:'integer',trigger: 'change'}],
                content: [{required: true,trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value || value.length === 0) {
                            return callback(new Error('请输入资讯内容'));
                        }
                        if (value && !checkEditorContent(value, 20000)) {
                            return callback(new Error('资讯内容不能超过20000字符'));
                        }
                        return callback();
                    }
                    }
                ],
            },
        }
    },
    methods: {
        getClassificationArray(){
            reqGetDictionaryListByCode({code: "wc_info_classification"}).then((res) => {
                this.classificationArray = res.data.data;
            }).catch(() => {
                this.classificationArray = [];
            });
        },
        handleSubmit() {
            this.loading = true;
            this.$refs['addForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                reqAddInfo(this.addForm).then((res)=>{
                    this.loading = false;
                    if (res.data.code === 1){
                        this.$Message.success('添加成功');
                        this.$emit('getInfo');
                        this.closeModal(false);
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('添加失败');
                })
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
                this.getClassificationArray()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
        let token = sessionStorage.getItem('Authorization')
        this.header = {Authorization: token}
    }
}
</script>
